<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white fixed md:static"
		>
			<span class="ml-2 font-bold">Editando perfil</span>
			<div class="flex items-center justify-center">
				<router-link to="/Cotizar">
					<button
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<Guardar class="w-6 h-6 text-white fill-current" />
						<span class="underline ml-2">Guardar Cambios</span>
					</button></router-link
				>
			</div>
		</div>
		<div class="flex mt-20 md:mt-4 items-center justify-center">
			<div v-if="me.avatar == '' || me.avatar == null">
				<div class="flex items-center">
					<user
						class="fill-current text-white rounded-full bg-gray-400 w-20 h-20 mx-2"
					/>
					<button
						@click="uploadFoto = !uploadFoto"
						class="ml-2 underline font-bold"
					>
						Insertar foto
					</button>
				</div>
				<input
					v-if="uploadFoto === true"
					class="w-full focus:outline-none mt-2"
					@change="subirFoto"
					type="file"
				/>
				<div v-if="cargando1">
					<p>{{ mensajeCarga1 }}</p>
				</div>
			</div>
			<div v-else>
				<div class="flex items-center justify-center">
					<img
						:src="process.env.VUE_APP_BACKEND_URL + '/storage' + me.avatar"
						class="rounded-full w-30 h-30 mx-2"
						alt=""
					/>
					<button
						@click="uploadFoto = !uploadFoto"
						class="ml-2 underline font-bold"
					>
						Insertar foto
					</button>
				</div>
				<input
					v-if="uploadFoto === true"
					class="w-full focus:outline-none mt-2"
					@change="subirFoto"
					type="file"
				/>
				<div v-if="cargando1">
					<p>{{ mensajeCarga1 }}</p>
				</div>
			</div>
		</div>
		<div class="mt-6">
			<div class="flex items-center justify-center mt-3 mx-2">
				<button
					@click="editar = !editar"
					class="bg-blue-500 text-white font-bold rounded-lg py-1 px-2"
				>
					<span v-if="editar == false">Editar</span>
					<span v-else>Editando</span>
				</button>
			</div>
			<div class="flex items-center justify-center mt-3 mx-2">
				<span class="text-botonT font-bold mr-3">Nombre y apellido:</span>
				<input
					type="text"
					class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 px-1 inline-flex items-center"
					v-model="me.name"
					:disabled="editar === false"
				/>
			</div>
			<div class="flex items-center justify-center mt-3 mx-2">
				<span class="text-botonT font-bold mr-3">Cedula de identidad:</span>
				<input
					type="text"
					class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 px-1 inline-flex items-center"
					v-model="me.cedula_identidad"
					:disabled="editar === false"
				/>
			</div>
			<div class="flex items-center justify-center mt-3 mx-2">
				<span class="text-botonT font-bold mr-2">Telefono movil:</span>
				<input
					type="text"
					class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 px-1 inline-flex items-center"
					v-model="me.numero_telefono_movil"
					:disabled="editar === false"
				/>
			</div>
			<div class="flex items-center justify-center mt-3 mx-2">
				<span class="text-botonT font-bold mr-2">Telefono local:</span>
				<input
					type="text"
					class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 px-1 inline-flex items-center"
					v-model="me.numero_telefono_local"
					:disabled="editar === false"
				/>
			</div>
			<div class="flex items-center justify-center mt-3 mx-2">
				<span class="text-botonT font-bold mr-2">Dirección:</span>
				<textarea
					class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 px-1 inline-flex items-center"
					v-model="me.address"
					:disabled="editar === false"
				></textarea>
			</div>
			<div class="flex items-center justify-center mt-10 mx-2 pt-10">
				<span class="text-botonT font-bold mr-5">Usuario:</span>
				<input
					type="text"
					class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 pl-1 inline-flex items-center"
					v-model="me.email"
					:disabled="editar === false"
				/>
			</div>
			<div class="flex items-center justify-center mt-4 mx-2">
				<button
					@click="EditPassword()"
					class="bg-blue-500 text-white font-bold rounded-full py-1 px-2"
				>
					Editar contraseña
				</button>
			</div>
			<div v-if="passEdit == true">
				<div class="flex items-center justify-center mt-3 mx-2">
					<span class="text-botonT font-bold mr-1">Contraseña Actual:</span>
					<input
						type="password"
						class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 pl-1 inline-flex items-center"
						v-model="oldPassword"
					/>
				</div>
				<div class="flex items-center justify-center mt-3 mx-2">
					<span class="text-botonT font-bold mr-1">Contraseña Nueva:</span>
					<input
						type="password"
						class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 pl-1 inline-flex items-center"
						v-model="newPassword"
					/>
				</div>
				<div class="flex items-center justify-center mt-3 mx-2">
					<span class="text-botonT font-bold mr-1 w-30"
						>Verificar Contraseña:</span
					>
					<input
						type="password"
						class="border border-secundB rounded-lg bg-secund text-black font-bold py-1 pl-1 inline-flex items-center"
						v-model="passwordConfirm"
					/>
				</div>
			</div>
		</div>
		<div
			v-if="editar == true || passEdit == true"
			class="flex items-center justify-center my-6"
		>
			<botonP
				@click="
					guardarCambios();
					cambiarPassword();
				"
				botonpText="Guardar"
			/>
			<router-link to="/VHome"
				><botonS botonsText="Descartar" class="ml-10"
			/></router-link>
		</div>
		<div v-else class="flex items-center justify-center my-6">
			<router-link to="/VHome"
				><botonP botonpText="Regresa a Home"
			/></router-link>
		</div>
	</div>
</template>
<script>
import user from "@/components/user.vue";
import botonP from "@/components/botonPrimario.vue";
import botonS from "@/components/botonSecundario.vue";
import gql from "graphql-tag";
import Guardar from "@/components/save.vue";

export default {
	name: "vendedorEdit",
	components: {
		user,
		botonP,
		botonS,
		Guardar
	},
	data() {
		return {
			uploadFoto: false,
			editar: false,
			passEdit: false,
			newPassword: "",
			passwordConfirm: "",
			cargando1: false,
			mensajeCarga1: "",
			oldPassword: ""
		};
	},
	apollo: {
		me: {
			query: gql`
				query {
					me {
						id
						avatar
						cedula_identidad
						name
						email
						numero_telefono_movil
						numero_telefono_local
						address
					}
				}
			`
		}
	},
	methods: {
		EditPassword() {
			this.passEdit = !this.passEdit;
		},
		subirFoto: async function({ target: { files = [] } }) {
			if (!files.length) {
				return;
			}
			this.mensajeCarga1 = "Subiendo foto...";
			this.cargando1 = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/uploadFile.gql"),
					variables: {
						file: files[0],
						oldFile: String(this.me.avatar)
					},
					context: {
						hasUpload: true
					}
				})
				.then(data => {
					this.me.avatar = data.data.uploadFiles;
					this.mensajeCarga1 = "Completado";
				})
				.catch(error => {
					this.mensajeCarga1 = error;
				});
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!, $avatar: String!) {
							actualizarVendedor(id: $id, input: { avatar: $avatar }) {
								id
							}
						}
					`,
					variables: {
						id: this.me.id,
						avatar: this.me.avatar
					}
				})
				.then(data => {
					this.mensaje = "Foto actualizada";
					this.showMessage = true;
				})
				.catch(error => {
					this.mensaje = error;
					this.showMessage = true;
				});
		},
		cambiarPassword() {
			if (this.passEdit == true) {
				if (this.newPassword == this.passwordConfirm) {
					//var pass={old_password:this.oldPassword, password:this.newPassword, password_confirmation:this.password};
					//console.log(pass)
					console.log(this.newPassword.length);
					this.$apollo
						.mutate({
							mutation: gql`
								mutation(
									$old_password: String!
									$password: String!
									$password_confirmation: String!
								) {
									updatePassword(
										input: {
											old_password: $old_password
											password: $password
											password_confirmation: $password_confirmation
										}
									) {
										message
									}
								}
							`,
							variables: {
								old_password: this.oldPassword,
								password: this.newPassword,
								password_confirmation: this.passwordConfirm
							}
						})
						.then(data => {
							this.mensaje = "Contraseña cambiada";
							this.showMessage = true;
						})
						.catch(error => {
							this.mensaje = error;
							this.showMessage = true;
						});
				} else {
					alert("Las contraseñas deben coincidir");
				}
			}
		},
		guardarCambios() {
			if (this.editar == true) {
				//var cambios = [];
				//cambios.push({name:this.me.name, numero_telefono_movil:this.me.numero_telefono_movil,address:this.me.address, email:this.me.email});
				this.$apollo
					.mutate({
						mutation: gql`
							mutation(
								$id: ID!
								$name: String!
								$numero_telefono_movil: String!
								$address: String!
								$email: String!
							) {
								actualizarVendedor(
									id: $id
									input: {
										name: $name
										numero_telefono_movil: $numero_telefono_movil
										address: $address
										email: $email
									}
								) {
									id
								}
							}
						`,
						variables: {
							id: this.me.id,
							name: this.me.name,
							cedula_identidad: this.me.cedula_identidad,
							numero_telefono_movil: this.me.numero_telefono_movil,
							numero_telefono_local: this.me.numero_telefono_local,
							address: this.me.address,
							email: this.me.email
						}
					})
					.then(data => {
						this.mensaje = "Datos actualizados";
						this.showMessage = true;
					})
					.catch(error => {
						this.mensaje = error;
						this.showMessage = true;
					});
			}
		}
	}
};
</script>
